import React, { useState } from 'react';
import s from './HomeCategories.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { publicApi } from '../../../redux/api';
import { ICategoryRoot } from '../../../models/ICategory';
import { findChildCategories } from '../../../helpers/findChildCategories';
import SubCategoriesGrid from '../SubCategoriesGrid/SubCategoriesGrid';

const HomeCategories = ({ rootMenu }: { rootMenu: ICategoryRoot[] }) => {
    const { lang } = useTranslation('common');

    const [activeCategory, setActiveCategory] = useState<string | undefined>();
    //TODO: move it down to children container
    const { data: subCategories, isSuccess } =
        publicApi.useGetSubCategoriesQuery({ category: activeCategory });

    const categoryClickHandler = (url: string) => {
        setActiveCategory(url);
    };
    let children = [];
    if (isSuccess) {
        children = findChildCategories(activeCategory, subCategories);
    }

    return (
        <div className={s.container}>
            <menu className={s.categories}>
                {rootMenu?.map((category) => (
                    <li key={category.id} className={s.categoryItem}>
                        <button
                            className={`${s.categoryButton}  ${
                                activeCategory === category.url
                                    ? s.categoryButton_active
                                    : ''
                            }`}
                            type="button"
                            onClick={() => categoryClickHandler(category.url)}
                        >
                            {category.lang[lang]}
                        </button>
                    </li>
                ))}
            </menu>

            {isSuccess && (
                <SubCategoriesGrid
                    items={children}
                    parentCategory={activeCategory}
                />
            )}
        </div>
    );
};

export default HomeCategories;
